import classNames from 'classnames';
import style from './loader.module.scss';

type Props = {
  button?: boolean;
};

const LoaderIcon: React.FC<Props> = ({ button }) => (
  <div
    className={classNames(style.wrapperLoader, {
      [style.button_loader]: button,
    })}
  >
    <svg
      className={style.icon}
      viewBox='0 0 252 252'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M252 126C252 195.588 195.588 252 126 252C56.4121 252 0 195.588 0 126C0 56.4121 56.4121 0 126 0C195.588 0 252 56.4121 252 126ZM11.0885 126C11.0885 189.464 62.5361 240.911 126 240.911C189.464 240.911 240.911 189.464 240.911 126C240.911 62.5361 189.464 11.0885 126 11.0885C62.5361 11.0885 11.0885 62.5361 11.0885 126Z' />
      <path d='M246.456 126C249.518 126 252.013 123.516 251.878 120.457C250.753 94.9025 241.871 70.2453 226.35 49.8034C209.708 27.887 186.35 12.0212 159.843 4.62997C133.335 -2.76124 105.137 -1.27139 79.5564 8.8719C55.6967 18.3327 35.3377 34.8365 21.152 56.1221C19.4539 58.6701 20.3037 62.0865 22.9239 63.6709C25.5441 65.2554 28.9417 64.4068 30.6506 61.8661C43.5756 42.6496 62.0354 27.7477 83.6436 19.1796C106.973 9.929 132.69 8.57026 156.864 15.311C181.039 22.0518 202.342 36.5213 217.519 56.509C231.576 75.0219 239.661 97.3256 240.778 120.457C240.925 123.516 243.394 126 246.456 126Z'>
        <animateTransform
          attributeName='transform'
          attributeType='XML'
          type='rotate'
          dur='1.5s'
          from='0 126 126'
          to='360 126 126'
          repeatCount='indefinite'
        />
      </path>
      <path d='M220 126C220 177.915 177.915 220 126 220C74.0852 220 32 177.915 32 126C32 74.0852 74.0852 32 126 32C177.915 32 220 74.0852 220 126ZM42.6526 126C42.6526 172.031 79.9685 209.347 126 209.347C172.031 209.347 209.347 172.031 209.347 126C209.347 79.9685 172.031 42.6526 126 42.6526C79.9685 42.6526 42.6526 79.9685 42.6526 126Z' />
      <path d='M72.7009 196.888C70.9384 199.232 71.4022 202.575 73.8421 204.202C89.3786 214.565 107.689 220.088 126.445 219.999C146.974 219.902 166.907 213.087 183.198 200.594C199.49 188.102 211.244 170.62 216.664 150.819C221.616 132.729 221.034 113.613 215.057 95.9195C214.119 93.1409 211.011 91.8256 208.289 92.9195C205.568 94.0134 204.266 97.1019 205.185 99.8872C210.308 115.422 210.76 132.163 206.421 148.015C201.612 165.579 191.187 181.086 176.736 192.166C162.285 203.247 144.604 209.292 126.394 209.378C109.959 209.456 93.9086 204.676 80.2355 195.697C77.7839 194.088 74.4634 194.544 72.7009 196.888Z'>
        <animateTransform
          attributeName='transform'
          attributeType='XML'
          type='rotate'
          dur='2s'
          from='360 126 126'
          to='0 126 126'
          repeatCount='indefinite'
        />
      </path>
    </svg>
  </div>
);

export default LoaderIcon;
