import Head from 'next/head';
import { useTranslation } from 'react-i18next';

import favicon from '@/assets/icons/favicon.ico';

type Props = {
  title: string;
};

const HeadData: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Head>
      <title>{t(title)}</title>
      <meta name='description' content='Put your description here.' />
      <meta property='og:image' content={favicon.src} />
      <link rel='icon' href={favicon.src} />
      <meta charSet='utf-8' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, maximum-scale=1, viewport-fit=cover'
      />
      <link rel='apple-touch-icon' href={favicon.src} />
      <link rel='android-touch-icon' href={favicon.src} />
    </Head>
  );
};

export default HeadData;
