import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';

import useWindowSize from '@/hooks/useWindowSize';

import style from '@/app.module.scss';
import { open, setLoader } from '@/redux/rootReducer';

import LoaderIcon from '@/components/UI/loader';
import HeadData from '@/components/headData';

const Header = dynamic(() => import('@/components/header'));
const MainContent = dynamic(() => import('@/components/mainContent'));
const Enter = dynamic(() => import('@/components/enter'));

const Home = () => {
  const { windowWidth } = useWindowSize();
  const enter = useAppSelector((state) => state.rootState.enter);
  const loader = useAppSelector((state) => state.rootState.loader.language);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (windowWidth <= 720) {
      document.body.style.overflow = enter ? 'hidden' : 'auto';
    }
  }, [windowWidth, enter]);

  useEffect(() => {
    setTimeout(
      () => dispatch(setLoader({ title: 'language', status: false })),
      800
    );
  }, [dispatch, loader]);

  return (
    <div className='main'>
      <HeadData title='titlePage.home' />
      {enter && (
        <div className={style.wrapper} onClick={() => dispatch(open())}></div>
      )}
      <Enter />
      {!loader && <Header />}
      <main>
        {loader ? (
          <div className={style.wrapper_loader}>
            <LoaderIcon />
          </div>
        ) : (
          <MainContent />
        )}
      </main>
    </div>
  );
};

export default Home;
