'use client';
import { useState, useEffect } from 'react';

type WindowDimensions = {
  windowWidth: number;
  windowHeight: number;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowDimensions>({
    windowWidth: 0,
    windowHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export default useWindowSize;
